import moment from "moment";
import $ from "jquery";

export default {
    formattedDate: function (date, year) {
        var fullDate = "";
        var dateArr = date.split("/"); //year, month, day
        if (dateArr.length > 1) {
            if (year == 0 || dateArr[0] == 0 || dateArr[0] == "") {
                fullDate = "Uncertain";
            } else {
                var day = dateArr[2] == "" ? "" : dateArr[2];
                var month = dateArr[1] == "" ? "" : moment(dateArr[1], "MM").format("MMMM ");
                var monthDay = month != "" || day != "" ? month + day + ", " : "";

                fullDate = monthDay + dateArr[0];
            }
        } else {
            fullDate = moment(date, "YYYY/MM/DD").format("MMMM DD, YYYY");
        }

        return fullDate;
    },

    BuildLitigationDate(entry) {
        var month = entry.effectiveMonth == "00" ? "" : parseInt(entry.effectiveMonth),
            day = entry.effectiveDay == 0 ? "" : entry.effectiveDay,
            year = typeof entry.effectiveYear != "number" ? 0 : entry.effectiveYear;

        return [year, month, day, new Date(year == 0 ? 1880 : year, month, day)];
    },

    BuildDecisionArr(entry) {
        var dateOfDecision = this.BuildLitigationDate(entry);
        return {
            cpUrl: `${document.location.origin}/tcl-base/entries/litigations/${entry.id}-${entry.slug}?redirectTo=${document.location.origin}/tcl-base/entries/litigations${document.location.origin}/${entry.id}-${entry.slug}`,
            id: entry.id,
            title: entry.title,
            url: entry.url,
            country: entry.country[0].title,
            region: entry.country[0].region[0].title,
            flag: entry.country[0].abbr.toLowerCase(),
            date: dateOfDecision[3],
            effectiveDate:
                dateOfDecision[0] +
                "/" +
                dateOfDecision[1] +
                "/" +
                dateOfDecision[2],
            year: dateOfDecision[0],
            publicComments: entry.publicComments,
            tobaccoControlTopic: entry.tobaccoControlTopics,
            substantiveIssue: entry.substantiveIssues,
            typeLitigation: entry.typeOfLitigation,
            typeProduct: entry.typeOfTobaccoProduct,
            language: entry.languageOfUploadedDecisionsDocument,
            intRegInstruments: entry.internationalRegionalInstruments
        };
    },

    formattedComments(text) {
        if (text == null) return "Information about this decision coming soon";
        var doc = new DOMParser().parseFromString(text, "text/html");
        return doc.body.innerHTML || "";
    },

    dowloadDecisions(orderedDecisions) {
        var filename = "advanced-search-result.csv";

        var fullEntries = orderedDecisions;

        var BOM = "\uFEFF";
        var csvContent = "Title, Country, Effective Date, Summary" + "\r\n";
        fullEntries.forEach(function (entry) {
            var publicComments =
                entry.publicComments != null
                    ? entry.publicComments
                          .replace(/<\/?[^>]+(>|$)/g, "")
                          .replace(/(\r\n|\n|\r|\t)/gm, "")
                          .replace(/"/g, "'")
                    : "";

            var effectiveDate = moment(entry.effectiveDate, "YYYY/MM/DD").format("MMMM DD YYYY");
            // entry.effectiveDate.toDateString();
            let row = JSON.stringify(entry.title) + "," + entry.country + "," + effectiveDate + "," + JSON.stringify(publicComments);
            csvContent += row + "\r\n";
        });

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        var blob = new Blob([BOM + csvContent], {
                type: "text/csv;charset=utf-8",
            }),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    },

    goToDetails(e, url) {
        $(e.target).parent().find(".comments").toggleClass("expanded");
        const text = $(e.target).text().trim() == "More" ? "Less" : "More";
        $(e.target).text(text);
    },

    groupBy(x, f) {
        return x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});
    },

    getLabelStatus(val) {
        let label = "";
        switch (val) {
            case "R":
                label = "Required";
                break;
            case "NR":
                label = "Not Required";
                break;
            case "SRQ":
                label = "Some Requirements";
                break;
            case "B":
                label = "Banned";
                break;
            case "BEMP":
                label = "Banned except as medicinal products";
                break;
            case "Y":
                label = "Yes";
                break;
            case "N":
                label = "No";
                break;
            case "SR":
                label = "Some Restrictions";
                break;
            case "A":
                label = "Allowed";
                break;
            case "U":
                label = "Uncertain";
                break;
            case "NA":
                label = "Not Applicable";
                break;
        }
        return label;
    },

    strToSlug(str) {
        let slug = str
            .toLowerCase() //LowerCase
            .trim() //remove spaces from start and end
            .replace(/\s+/g, "-") // space to -
            .replace(/&/g, `-and-`) // & to and
            .replace(/%/g, ``) // & to and
            .replace(/--/g, `-`); // -- to -

        return slug;
    },

    capitalizeStr(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    },

    cardUrl() {
        $(".major.decision-card").click(function (e) {
            if ($(e.target).hasClass("toggle-comments")) {
                $(".comments").toggleClass("expanded");
                const text = $(e.target).text().trim() == "More" ? "Less" : "More";

                $(".toggle-comments").text(text);
                return;
            }
            window.location = $(this).data("href");
        });
    },

    filterByCaseDocument() {
        return true;
    },

    debounce(func, delay) {
        let timeoutId;

        return function (...args) {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    },

    includesWord(decision, document, word) {
        if (document == null) return false;
        const str = JSON.stringify({ ...decision, document }).toLowerCase();
        return str.includes(word.toLowerCase());
    },
};
